import { Injectable, Optional, Inject } from '@angular/core';
import { NativeDateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable()
export class UTCDateAdapter extends NativeDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string) {
    super(matDateLocale); // Pass locale to the base class
  }

  /**
   * Parses a date string or Date object and ensures it's handled in UTC.
   */
  override parse(value: unknown): Date | null {
    if (typeof value === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
      const [month, day, year] = value.split('/').map(Number);
      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        return new Date(Date.UTC(year, month - 1, day));
      }
    }
    if (value instanceof Date && !isNaN(value.getTime())) {
      return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
    }
    return null; // Return null for invalid inputs
  }

  /**
   * Always creates a date in UTC, avoiding local timezone offsets.
   */
  override createDate(year: number, month: number, date: number): Date {
    return new Date(Date.UTC(year, month, date));
  }

  /**
   * Formats a date as MM/DD/YYYY in UTC.
   */
  override format(date: Date, displayFormat?: Object): string {
    const utcDate = new Date(Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    ));
    const day = String(utcDate.getUTCDate()).padStart(2, '0');
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
    const year = utcDate.getUTCFullYear();
    return `${month}/${day}/${year}`; // Format as MM/DD/YYYY
  }
}
